function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//// MAIN NAVIGATION ////

$(function() {
	if ($(window).scrollTop() > 0) {
		$('header.header').addClass('--scroll');
	} else {
		$('header.header').removeClass('--scroll');
	}

	$(window).on('scroll', function() {
		if ($(window).width() < 992 && $(window).width() > 575) {
			if ($(window).scrollTop() > 72) {
				$('header.header').addClass('--scroll');
			} else {
				$('header.header').removeClass('--scroll');
			}
		} else if ($(window).width() < 576) {
			if ($(window).scrollTop() > 0) {
				$('header.header').addClass('--scroll');
			} else {
				$('header.header').removeClass('--scroll');
			}
		}
	});

	//// MOBILE ////

	$('a.mobile-nav__toggle-menu').on('click', function(event) {
		event.preventDefault();

		if ($('ul#menu-galvenais.menu').css('visibility') == 'hidden') {
			$('ul#menu-galvenais.menu').addClass('--active').removeClass('--hidden');
			$('body').addClass('--modal-open');
			$(this).addClass('--active');
		} else {
			$('ul#menu-galvenais.menu').removeClass('--active').addClass('--hidden');
			$('body').removeClass('--modal-open');
			$(this).removeClass('--active');
		}

		if ($('input.mobile-nav-items__searchbox').css('visibility') == 'visible') {
			$('input.mobile-nav-items__searchbox').removeClass('--active');
		}
	});

	$(window).on('resize orientationchange', function() {
		if ($('ul#menu-galvenais.menu').css('visibility') == 'visible') {
			$('ul#menu-galvenais.menu').removeClass('--active').removeClass('--hidden');
		}

		$('input.mobile-nav-items__searchbox').removeClass('--active');
		$('body').removeClass('--modal-open');
	});

	$('a.mobile-nav__toggle-search').on('click', function(event) {
		event.preventDefault();

		if ($('input.mobile-nav-items__searchbox').css('visibility') == 'hidden') {
			$('input.mobile-nav-items__searchbox').addClass('--active').focus();
			$(this).addClass('--active');
		} else {
			$('input.mobile-nav-items__searchbox').removeClass('--active');
			$(this).removeClass('--active');
		}

		if ($('ul#menu-galvenais.menu').css('visibility') == 'visible') {
			$('ul#menu-galvenais.menu').removeClass('--active');
			$('body').removeClass('--modal-open');
		}

		$('.main-nav__nav-items__nav-item .__dropdown-toggle').removeClass('--active');
		$('.main-nav__nav-items__nav-item ul.__dropdown-menu').removeClass('--active');
	});



	$('input.mobile-nav-items__searchbox').on('transitionend', function() {
		if ($(this).hasClass('--active')) {
			$(this).focus();
		}
	});

	$(window).on('resize orientationchange', function() {
		if ($(window).width() >= 992) {
			$('input.mobile-nav-items__searchbox').removeClass('--active');
		}
	});

	$('.mobile-nav-items__button').on('click', function() {
		$('.mobile-nav-items__button').not($(this)).removeClass('--active');
	});

	$(window).on('resize orientationchange', function() {
		$('.mobile-nav-items__button').removeClass('--active');
	});

	$('.main-nav__nav-items__nav-item .__dropdown-toggle').on('click', function(event) {
		event.preventDefault();

		if ($(this).parents('.main-nav__nav-items__nav-item').find('.__dropdown-menu').css('visibility') != 'visible') {
			$('.main-nav__nav-items__nav-item .__dropdown-toggle').removeClass('--active');
			$('.main-nav__nav-items__nav-item .__dropdown-menu:visible').removeClass('--active');
			$(this).addClass('--active');
			$(this).parents('.main-nav__nav-items__nav-item').find('ul.__dropdown-menu').addClass('--active');
		} else {
			$(this).removeClass('--active');
			$(this).parents('.main-nav__nav-items__nav-item').find('ul.__dropdown-menu').removeClass('--active');
		}
	});

	$(window).on('resize orientationchange', function() {
		$('.main-nav__nav-items__nav-item .__dropdown-toggle').removeClass('--active');
		$('.main-nav__nav-items__nav-item ul.__dropdown-menu').removeClass('--active');
	});

	//// DESKTOP ////

	$('li.__nav-item-dropdown').on('mouseover', function() {
		if ($(window).width() >= 992 && (!($('.main-nav__search-field').hasClass('--active')))) {
			$(this).find('ul.__dropdown-menu').addClass('--active');
		}
	});

	$('li.__nav-item-dropdown').on('mouseout', function() {
		if ($(window).width() >= 992 && (!($('.main-nav__search-field').hasClass('--active')))) {
			$(this).find('ul.__dropdown-menu').removeClass('--active');
		}
	});

	$('a.main-nav__toggle-search').on('mousedown', function(event) {
		if ($('.main-nav__search-field').css('visibility') == 'hidden') {
			$('a.main-nav__toggle-search').addClass('--active');
			$('.main-nav__search-field').addClass('--active');
		} else {
			$('a.main-nav__toggle-search').removeClass('--active');
			$('.main-nav__search-field').removeClass('--active');
		}
	});

	$('a.main-nav__toggle-search').on('click', function(event) {
		event.preventDefault();
	});

	$('.main-nav__search-field').on('transitionend', function() {
		if ($(this).hasClass('--active')) {
			$('.main-nav__search-field input.main-nav__search-field__input').focus();
		}
	});

	$('input.main-nav__search-field__input').on('focusout', function() {
		$('a.main-nav__toggle-search').removeClass('--active');
		$('.main-nav__search-field').removeClass('--active');
	});

});


//// RECENT NEWS ////

$(function() {
	$('section.main-page-section.recent-news .main-page-section__content .--isotope-grid').isotope({
	    itemSelector: '.--isotope-item',
	    layoutMode: 'masonry',
	    transitionDuration: 0
	});
});

//// RECENT BOOKS ////

$(function() {
	if ($(window).width() >= 992) {
		$('.recent-books-carousel__book').each(function() {
			$(this).width(getRandomInt(200,250));
		});
	} else {
		$('.recent-books-carousel__book').each(function() {
			$(this).css('width',$('.recent-books-carousel').width() / 3 - 10+'px');
		});
	}

	$('.recent-books-carousel').owlCarousel({
		autoWidth: true,
		smartSpeed: 600,
		dragEndSpeed: 200,
	});

	$(window).on('resize orientationchange', function() {
		if ($(window).width() < 992) {
			$('.recent-books-carousel').owlCarousel('destroy');

			$('.recent-books-carousel__book').each(function() {
				$(this).css('width',$('.recent-books-carousel').width() / 3 - 10+'px');
			});

			$(".recent-books-carousel").owlCarousel({
				autoWidth: true,
				smartSpeed: 600,
				dragEndSpeed: 200,
			});

			$('.recent-books-carousel .owl-stage-outer').addClass('--last-item-invisible');	
		} else {
			$('.recent-books-carousel').owlCarousel('destroy');

			$('.recent-books-carousel__book').each(function() {
				$(this).width(getRandomInt(200,250));
			});

			$(".recent-books-carousel").owlCarousel({
				autoWidth: true,
				smartSpeed: 600,
				dragEndSpeed: 200,
			});

			$('.recent-books-carousel .owl-stage-outer').addClass('--last-item-invisible');
		}
	});

	$('.recent-books-carousel .owl-stage-outer').addClass('--last-item-invisible');
	
	$('.recent-books-carousel').on('translated.owl.carousel', function() {
		var firstItemOffset = $('.recent-books-carousel .owl-item').first().offset();
		var lastItemOffset = $('.recent-books-carousel .owl-item').last().offset();
		var lastItemOffsetRight = ($(window).width() - (lastItemOffset.left + $('.recent-books-carousel .owl-item').last().outerWidth()));
		var stageOffset = $('.recent-books-carousel .owl-stage-outer').offset();
		var stageOffsetRight = ($(window).width() - (stageOffset.left + $('.recent-books-carousel .owl-stage-outer').outerWidth()));

		if (firstItemOffset.left < stageOffset.left) {
			$('.recent-books-carousel .owl-stage-outer').addClass('--first-item-invisible');
		} else {
			$('.recent-books-carousel .owl-stage-outer').removeClass('--first-item-invisible');
		}

		if (lastItemOffsetRight < stageOffsetRight) {
			$('.recent-books-carousel .owl-stage-outer').addClass('--last-item-invisible');
		} else {
			$('.recent-books-carousel .owl-stage-outer').removeClass('--last-item-invisible');
		}
	});

	$('.recent-books-carousel .recent-books-carousel__book a').magnificPopup({
	 	type: 'image',
	 	mainClass: 'mfp-with-zoom mfp-img-mobile mfp-recent-books',
	 	closeOnContentClick: true,
	 	showCloseBtn: false,
	 	closeBtnInside: false,
	 	image: {
	 		 titleSrc: 'data-info'
	 	},
	 	zoom: {
			enabled: true,
			duration: 200, // don't foget to change the duration also in CSS
			opener: function(element) {
				return element.find('img');
			}
		}
	});
});

//// GENERIC CONTENT ////

$(function() {

	//// GENERIC ARTICLE GALLERY ////

	$('.section-generic__article__gallery').each(function() {
		$(this).magnificPopup({
			delegate: 'a.section-generic__article__gallery__gallery-image',
		 	type: 'image',
		 	mainClass: 'mfp-with-zoom mfp-img-mobile mfp-generic-content',
		 	closeBtnInside: false,
		 	fixedBgPos: true,
		 	image: {
		 		 titleSrc: 'data-info',
		 		 verticalFit: true,
		 	},
		 	zoom: {
				enabled: true,
				duration: 200,
				opener: function(element) {
					return element.find('img');
				},
			},
			gallery: {
			    enabled: true,
			    tCounter: '<span class="mfp-counter">Attēls nr. %curr% no %total%</span>',
			    arrowMarkup: '<button type="button" class="gallery-control fa fa-angle-%dir%"></button>',
			},
			callbacks: {
		        change: function() {
		            if (this.isOpen) {
		                this.wrap.addClass('mfp-open');
		            }
		        }
			}
	    });
    });

    //// GENERIC ARTICLE NAV ////

    $(function() {
		$('.footer__nav-scroll-top').on('click', function(event) {
			event.preventDefault();
			$("html, body").animate({ scrollTop: 0 }, 1200);
		});
	});

	var footerHeight = $('footer.footer').height();
	var footerOffsetTop = $('footer.footer').offset().top;

	if ($('ul.generic-content__generic-content-nav').length) {
		var genericContentNavOffsetTop = $('ul.generic-content__generic-content-nav').offset().top;
	    var genericContentNavBottom = $(window).scrollTop() + $('ul.generic-content__generic-content-nav').outerHeight(true);
	    var genericContentNavOverflow = genericContentNavBottom - $('footer.footer').scrollTop();

		if ($(window).scrollTop() > genericContentNavOffsetTop) {
			$('ul.generic-content__generic-content-nav').addClass('--scroll');
		} else {
			$('ul.generic-content__generic-content-nav').removeClass('--scroll');
		}

		if (genericContentNavBottom + 350 >= footerOffsetTop) {
			$('ul.generic-content__generic-content-nav').addClass('--scroll-bottom');
		} else {
			$('ul.generic-content__generic-content-nav').removeClass('--scroll-bottom');
		}

		$(window).on('scroll orientationchange resize', function() {
			if ($(window).width() >= 992) {
				if ($(window).scrollTop() > genericContentNavOffsetTop) {
					if ($(document).height() >= 2000) {
						$('ul.generic-content__generic-content-nav').addClass('--scroll');

						genericContentNavBottom = $(window).scrollTop() + $('ul.generic-content__generic-content-nav').outerHeight(true);
			    		footerOffsetTop = $('footer.footer').offset().top;

						if (genericContentNavBottom + 350 >= footerOffsetTop) {
							$('ul.generic-content__generic-content-nav').addClass('--scroll-bottom');
						} else {
							$('ul.generic-content__generic-content-nav').removeClass('--scroll-bottom');
						}
					}
				} else {
					$('ul.generic-content__generic-content-nav').removeClass('--scroll');
				}
			} else {
				$('ul.generic-content__generic-content-nav').removeClass('--scroll');
				$('ul.generic-content__generic-content-nav').removeClass('--scroll-bottom');
			}
		});
	}

	//// GENERIC ARTICLE POST INFO ////

	if ($('ul.generic-content__post-article-info').length) {
		var genericBlogPostOffsetTop = $('ul.generic-content__post-article-info').offset().top;
	    var genericBlogPostBottom = $(window).scrollTop() + $('ul.generic-content__post-article-info').outerHeight(true);
	    var gemericBlogPostOverflow = genericBlogPostBottom - $('footer.footer').scrollTop();

		if ($(window).scrollTop() > genericBlogPostOffsetTop) {
			$('ul.generic-content__post-article-info').addClass('--scroll');
		} else {
			$('ul.generic-content__post-article-info').removeClass('--scroll');
		}

		if (genericBlogPostBottom + 350 >= footerOffsetTop) {
			$('ul.generic-content__post-article-info').addClass('--scroll-bottom');
		} else {
			$('ul.generic-content__post-article-info').removeClass('--scroll-bottom');
		}

		$(window).on('scroll orientationchange resize', function() {
			if ($(window).width() >= 992) {
				if ($(window).scrollTop() > genericBlogPostOffsetTop) {
					if ($(document).height() >= 2000) {
						$('ul.generic-content__post-article-info').addClass('--scroll');

						genericBlogPostBottom = $(window).scrollTop() + $('ul.generic-content__post-article-info').outerHeight(true);
			    		footerOffsetTop = $('footer.footer').offset().top;

						if (genericBlogPostBottom + 350 >= footerOffsetTop) {
							$('ul.generic-content__post-article-info').addClass('--scroll-bottom');
						} else {
							$('ul.generic-content__post-article-info').removeClass('--scroll-bottom');
						}
					}
				} else {
					$('ul.generic-content__post-article-info').removeClass('--scroll');
				}
			} else {
				$('ul.generic-content__post-article-info').removeClass('--scroll');
				$('ul.generic-content__post-article-info').removeClass('--scroll-bottom');
			}
		});
	}

	//// BLOG ARTICLES LIST ////

	$('section.blog-articles .blog-articles__blog-articles-list .--isotope-grid').isotope({
	    itemSelector: '.--isotope-item',
	    layoutMode: 'masonry',
	    transitionDuration: 0
	});

	//// EVENTS LIST ////

	if ($('section.section-generic.events').length) {
		if ($(window).width() >= 992) {
			$('html, body').animate({
    			scrollTop: ($('.events__events-list__month.--current').offset().top) - 30
			},1200);
		} else {
			$('html, body').animate({
    			scrollTop: ($('.events__events-list__month.--current').offset().top) - 80
			},1200);
		}

		
	}

	//// BOOKS LIST ///

	$('section.books .books__books-list .books__books-list__month__books').isotope({
	    itemSelector: '.books__books-list__month__books__book',
	    layoutMode: 'masonry',
	    transitionDuration: 0
	});


	$('.books__books-list__month__books').each(function() {
		$(this).magnificPopup({
			delegate: 'a.books__books-list__month__books__book',
		 	type: 'image',
		 	mainClass: 'mfp-with-zoom mfp-img-mobile mfp-generic-content',
		 	closeBtnInside: false,
		 	fixedBgPos: true,
		 	image: {
		 		 titleSrc: 'data-info',
		 		 verticalFit: true,
		 	},
		 	zoom: {
				enabled: true,
				duration: 200,
				opener: function(element) {
					return element.find('img');
				},
			},
			gallery: {
			    enabled: true,
			    tCounter: '<span class="mfp-counter">Attēls nr. %curr% no %total%</span>',
			    arrowMarkup: '<button type="button" class="gallery-control fa fa-angle-%dir%"></button>',
			},
			callbacks: {
		        change: function() {
		            if (this.isOpen) {
		                this.wrap.addClass('mfp-open');
		            }
		        }
			}
	    });
    });
});

